/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import axios from 'axios';

// UI lib components
import { Col, Container, Row, Visible } from 'react-grid-system';

// UI local components
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/page-solutions/banner';
import BeardCrumbs from '../page-components/breadCrumbs';

// Assests
import BANNER_IMG from '../images/references/refBanner.png';

// Styles
import '../page-styles/references.scss';

/* -------------------------------------------------------------------------- */
/*                          All References Page                               */
/* -------------------------------------------------------------------------- */

function AllReferencesPage() {
  /* ********************************** CONST ********************************* */
  const SITE_TITLE = 'LUXOR TECHNOLOGY AND TRADE | Réferences';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    // eslint-disable-next-line max-len
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dans le développement des solutions techniques pour la construction. ';

  const localStorage = typeof window !== 'undefined' && window.localStorage;
  /* ********************************** HOOKS ********************************* */
  const [references, setReferences] = useState([]);

  // LOCALIZATION
  const { t } = useTranslation();
  const { ALL_CATEGORIES } = t('categoryReference', { returnObjects: true });
  const [selectedCategory, setCategory] = useState(ALL_CATEGORIES[0]);

  const GET_FILTERED_REFERENCES = references.filter(({ category }) =>
    category.split(',').includes(selectedCategory.value),
  );
  const { language } = useI18next();
  useEffect(() => {
    if (language === 'fr') {
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://admin.luxor.com.tn/api/1.1/tables/reference/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
        )
        .then(({ data: { data } }) => {
          localStorage.setItem('references-array', JSON.stringify(data));
          setReferences(data);
        })
        .catch((error) => error);
    } else if (language === 'en') {
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://admin.luxor.com.tn/api/1.1/tables/referenceen/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
        )
        .then(({ data: { data } }) => {
          localStorage.setItem('references-array', JSON.stringify(data));
          setReferences(data);
        })
        .catch((error) => error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /* ********************************* HELPERS ******************************** */
  function getLink() {
    return '/details-reference';
  }
  // LOCALIZATION
  const { title, titleBreadcrumbs, pageLink } = t('reference', {
    returnObjects: true,
  });

  const sortByRank = (a, b) => {
    if (+a.rank < +b.rank) {
      return -1;
    }
    if (+a.rank > +b.rank) {
      return 1;
    }
    return 0;
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} />
      <Container className="references-page fluid" fluid>
        <BannerSection bannerImage={BANNER_IMG}>
          <h1>{title}</h1>
        </BannerSection>
        <Visible lg xl xxl>
          <Container className="ml-15 fluid" fluid>
            <BeardCrumbs path={pageLink} titleBreadcrumbs={titleBreadcrumbs} />
          </Container>
        </Visible>
        <Container className="all-categories flex items-center ml-15 fluid">
          {ALL_CATEGORIES.map(({ id, name, value }) => {
            // eslint-disable-next-line operator-linebreak
            const IS_SELECTED_CATEOGORY =
              selectedCategory.name === name && id === selectedCategory.id;

            return (
              <Container
                className="single-category fluid"
                key={id}
                onClick={() => {
                  setCategory({ name, id, value });
                }}
                fluid
              >
                <p
                  className={`category-title ${
                    IS_SELECTED_CATEOGORY && 'active-category'
                  }`}
                >
                  {name}
                </p>
                <span
                  className={`category-underline ${
                    IS_SELECTED_CATEOGORY && 'show-category-underline'
                  }`}
                />
              </Container>
            );
          })}
        </Container>
        <Row className="single-reference-wrapper top pl-15 fluid">
          {GET_FILTERED_REFERENCES?.sort(sortByRank).map((element) => (
            <Col
              className="single-reference-container fluid"
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              onClick={() => {
                localStorage.setItem(
                  'singleReference',
                  JSON.stringify(element),
                );

                navigate(getLink());
              }}
            >
              <Container className="single-reference fluid" fluid>
                <div className="overlay flex justify-center items-center">
                  <h2 id="project-name">{element.project_name}</h2>
                </div>
                <img
                  src={`https://admin.luxor.com.tn/${element.main_picture?.data.url}`}
                  alt={element.project_name}
                />
              </Container>
            </Col>
          ))}
        </Row>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Reference", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AllReferencesPage;
